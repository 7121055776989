import type { TravelFormRooms } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

export const TravelFormRoomElement = {
    ADULT: 'A',
    CHILD_WITHOUT_AGE: 0,
} as const;

type TravelFormError = {
    errorText: string;
    hintText: string;
    isError: boolean;
};

export type AllocationState = {
    adultsCount: number;
    childrenCount: number;
    errors: {
        doChildrenWithoutAgeExist: boolean;
        doChildrenWithoutAgeExistInCustomAllocation: boolean;
        doRoomsWithoutAdultsExist: boolean;
        exceedsMaxAdultsPerRoom: TravelFormError;
        exceedsMaxChildrenPerRoom: TravelFormError;
        minAdultsPerRoom: TravelFormError;
    };
    listOfChildren: number[];
    rooms: TravelFormRooms;
    roomsCount: number;
};
