import React from 'react';

import DropdownSelect from '@/core/components/dropdown-select/dropdown-select';

import styles from './travel-form-room-age-allocation-desktop.module.scss';

type TravelFormAgeOptions = {
    name: number;
    title: string;
}[];

type TravelFormRoomWithChildrenProps = {
    ageOptions: TravelFormAgeOptions;
    areAllChildrenAgesAllocated: boolean;
    handleOptionChange: (index: number, value: number) => void;
    listOfChildren: number[];
};

export default function TravelFormAgeAllocationDesktop({
    ageOptions,
    areAllChildrenAgesAllocated,
    handleOptionChange,
    listOfChildren,
}: TravelFormRoomWithChildrenProps) {
    return (
        <div className={styles.dropDownWrapper}>
            <div>Alter der Kinder bei Rückreise</div>
            {listOfChildren.map((child, index) => {
                return (
                    <div
                        className={'flex-space-between-horizontal'}
                        key={index}
                    >
                        <div>{`Kind ${index + 1}`}</div>
                        <div className={'flex-space-between-horizontal'}>
                            <DropdownSelect
                                onChange={(value) =>
                                    handleOptionChange(
                                        index,
                                        ageOptions.find((option) => option.title === value)?.name || 0,
                                    )
                                }
                                options={ageOptions.map((option) => ({
                                    title: option.title,
                                }))}
                                qaId={'qa-travel-from-room-age-desktop'}
                                qaOptionId={'qa-travel-from-room-age-option-desktop'}
                                selectedValue={ageOptions.find((option) => option.name === child)?.title || ''}
                            />
                        </div>
                    </div>
                );
            })}
            {!areAllChildrenAgesAllocated && (
                <div className={`${styles.hintText} ${styles.isInvalidAllocation}`}>
                    Wähle für jedes Kind das entsprechende Alter bei Rückreise.
                </div>
            )}
        </div>
    );
}
