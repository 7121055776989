import type {
    TravelFormAirportResult,
    TravelFormDataV1_jsonld_page_read,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import debounce from 'lodash.debounce';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';

export default function useTravelFormAirportOverlay(
    airportRequestTarget: null | string,
    selectedItems: TravelFormAirportResult[] | undefined,
    queryConfig?: { enabled?: boolean },
) {
    const [wasActivelyUsed, setWasActivelyUsed] = React.useState<boolean>(false);
    const [selectedAirports, setSelectedAirports] = React.useState<TravelFormAirportResult[]>(selectedItems ?? []);
    const [targetComponentIri, setTargetComponentIri] = React.useState<null | string>(airportRequestTarget);
    const [searchQuery, setSearchQuery] = React.useState('');

    const { data, isLoading } = useComponentDataByIri<TravelFormDataV1_jsonld_page_read>(targetComponentIri, {
        config: queryConfig,
        queryName: 'travelFormAirportOverlay',
    });

    const airportsResultSet = data?.attributes?.filters.airports?.resultSet;
    const defaultAirport = airportsResultSet
        ?.flatMap((resultSet) => {
            return resultSet.items;
        })
        .find((airport) => airport.isDefault);

    const setDebouncedAirportSearchQuery = React.useRef(
        debounce((query: string, airportRequestTarget: string) => {
            if (query.length === 0) {
                setSearchQuery('');
                setTargetComponentIri(airportRequestTarget);
                return;
            }
            if (!airportRequestTarget) {
                return;
            }
            const URL = updateComponentIriParam('userQuery', airportRequestTarget, { query: query.toString() });
            setTargetComponentIri(URL.pathname + URL.search);
            setSearchQuery(query);
        }, 300),
    ).current;

    const searchAirport = (searchValue: string, airportRequestTarget: string) => {
        setDebouncedAirportSearchQuery(searchValue, airportRequestTarget);
    };

    const resetSearch = (selectedItems: TravelFormAirportResult[]) => {
        setSelectedAirports(selectedItems);
        setTargetComponentIri(airportRequestTarget);
        setSearchQuery('');
    };

    const handleDefaultSelection = (nextSelectedAirports: TravelFormAirportResult[]) => {
        if (nextSelectedAirports.length === 0 && defaultAirport) {
            return [defaultAirport];
        }
        return nextSelectedAirports;
    };

    const onAirportClick = (
        airport: TravelFormAirportResult,
        event: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
        onDefaultClick?: () => void,
    ) => {
        event.stopPropagation();

        setWasActivelyUsed(true);
        if (airport.isDefault && defaultAirport) {
            setSelectedAirports([defaultAirport]);
            return onDefaultClick?.();
        }

        if (selectedAirports.some((airport) => airport.isDefault) && defaultAirport) {
            removeAirport(defaultAirport);
        }

        if (selectedAirports.map((airport) => airport.name).includes(airport.name)) {
            removeAirport(airport);
        } else {
            addAirport(airport);
        }
    };

    const addAirport = (airport: TravelFormAirportResult) => {
        setSelectedAirports((prev) => {
            const nextSelectedAirports = prev.map((airport) => airport.name).includes(airport.name)
                ? prev
                : [...prev, airport].filter((airport) => !airport.isDefault);
            return handleDefaultSelection(nextSelectedAirports);
        });
    };

    const removeAirport = (airport: TravelFormAirportResult) => {
        setSelectedAirports((prev) => {
            const toRemoveAirportIndex = prev.findIndex((_airport) => airport.name === _airport.name);
            const nextSelectedAirports = prev.filter((_destination, index) => toRemoveAirportIndex !== index);
            return handleDefaultSelection(nextSelectedAirports);
        });
    };

    const onChipItemClick = (airportName: string) => {
        const airport = selectedAirports.find((airport) => airport.name === airportName);
        if (!airport) {
            return;
        }
        removeAirport(airport);
    };

    // since we load travelForm overlays before TravelFormDataV1 is fully loaded, we have to manage certain states manually
    React.useEffect(() => {
        if (airportRequestTarget && !targetComponentIri && queryConfig?.enabled) {
            setTargetComponentIri(airportRequestTarget);
            setSelectedAirports(selectedItems ?? []);
        }
    }, [airportRequestTarget, queryConfig?.enabled, selectedItems, targetComponentIri]);

    return {
        defaultAirport,
        handleDefaultSelection,
        isLoading,
        onAirportClick,
        onChipItemClick,
        resetSearch,
        result: data?.attributes?.filters.airports,
        searchAirport,
        searchQuery,
        selectedAirports,
        setSelectedAirports,
        wasActivelyUsed,
    };
}
