import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormField, TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import { Button, Tooltip } from '@/core/features';
import { useRouterLink } from '@/core/features/router/router-link';
import useSticky from '@/core/features/scroll/use-sticky';
import { bemModule } from '@/core/utils/bem-classname';
import useTravelForm from '@/features/travel-form/travel-form-expanded/use-travel-form';
import TravelFormActivityOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity-overlay-desktop/travel-form-activity-overlay-container-desktop';
import TravelFormAirportOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-airport-overlay/travel-form-airport-overlay-desktop/travel-form-airport-overlay-container-desktop';
import TravelFormDatePickerOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/travel-form-date-picker-overlay-desktop/travel-form-date-picker-overlay-container-desktop';
import TravelFormDestinationOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/travel-form-destination-overlay-desktop/travel-form-destination-overlay-container-desktop';
import TravelFormDurationOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/travel-form-duration-overlay-desktop/travel-form-duration-overlay-container-desktop';
import TravelFormRoomAllocationContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-overlay-desktop/travel-form-room-allocation-overlay-container-desktop';
import { useTravelFormChanged } from '@/features/travel-form/travel-form-state';

import styles from './travel-form-desktop.module.scss';

const bem = bemModule(styles);

export type TravelFormDesktopFormConfiguration = {
    activeFilters: TravelFormDataV1Attributes['activeFilters'];
    cta: {
        dependsOn: TravelFormFilterName[];
        text: string;
    };
    fields: Record<keyof TravelFormDataV1Attributes['filters'], TravelFormField | null> | null;
    inputSize: 'large' | 'normal';
    noPadding?: boolean;
    onFormLoaded?: () => void;
    onInputClick: (overlayName: TravelFormOverlayName) => void;
    onSubmit: () => void;
    orientation: 'horizontal' | 'vertical';
};

export type TravelFormDesktopProps = {
    activityRequestTarget: string | undefined;
    airportRequestTarget: string | undefined;
    dateRequestTarget: string | undefined;
    destinationChangeRequestTarget: string | undefined;
    destinationRequestTarget: string | undefined;
    disableCtaTooltip?: boolean;
    formConfiguration: TravelFormDesktopFormConfiguration;
    isOverlayOpen: boolean;
    isSticky?: boolean;
    isWidget: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => void;
    overlayType: TravelFormOverlayName | null;
    travelForm: TravelFormDataV1Attributes | null;
};

export default function TravelFormDesktop({
    activityRequestTarget,
    airportRequestTarget,
    dateRequestTarget,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    disableCtaTooltip,
    formConfiguration,
    isOverlayOpen,
    isSticky = false,
    isWidget,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    overlayType,
    travelForm,
}: TravelFormDesktopProps) {
    const [hasTravelFormChanged, setHasTravelFormChanged] = useTravelFormChanged();
    const { ref: travelFormDesktopRef, stickyStyle } = useSticky(isSticky ? 'TravelFormDesktop' : null);
    const { navigate } = useRouterLink();
    const { activeFilters, fields, inputSize, onSubmit, orientation } = formConfiguration;
    const { isInputDisabled, isInputError, onInputClickHandler, onSubmitHandler } = useTravelForm({
        activeFilters,
        onInputClick,
        onSubmit,
        onTravelFormLoaded: () => {},
    });

    const [wasDestinationChanged, setWasDestinationChanged] = React.useState(false);

    const onDestinationOverlayCtaClick = (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => {
        setWasDestinationChanged(true);
        onOverlayCtaClick(filterName, filterValue, requestTarget);
    };
    // The Api can tell us to directly redirect to the url they provided. This is the case for e.g. a destination change
    // to directly redirect from S2 to S1. Normally the user has to click the CTA
    React.useEffect(() => {
        if (!wasDestinationChanged) {
            return;
        }

        if (travelForm?.cta?.redirectImmediately && travelForm?.filters.destination?.selectedItems) {
            navigate(travelForm?.cta.linkUrl);
            setWasDestinationChanged(false);
        }

        const timeout = setTimeout(() => {
            setWasDestinationChanged(false);
            // fallback to make sure state is cleared up
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [navigate, travelForm?.cta, travelForm?.filters.destination?.selectedItems, wasDestinationChanged]);

    // Reset the hasTravelFormChanged state when mounting the component
    React.useEffect(() => {
        setHasTravelFormChanged(false);
    }, [setHasTravelFormChanged]);

    return (
        <div
            className={bem(styles.travelFormDesktop, { [orientation]: true })}
            ref={travelFormDesktopRef}
            style={stickyStyle}
        >
            <div className={bem(styles.inputWrapper, { [orientation]: true })}>
                {fields?.activity && (
                    <TravelFormActivityOverlayContainerDesktop
                        activity={travelForm?.filters.activity}
                        activityRequestTarget={activityRequestTarget}
                        formConfiguration={formConfiguration}
                        hasError={isInputError('activity')}
                        inputName={'activity'}
                        isOverlayVisible={isOverlayOpen && overlayType === 'activity'}
                        onInputClick={() => onInputClickHandler('activity', fields?.activity?.dependsOn)}
                        onOverlayClose={onOverlayClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )}
                {fields?.destination && (
                    <TravelFormDestinationOverlayContainerDesktop
                        destination={travelForm?.filters.destination}
                        destinationChangeRequestTarget={destinationChangeRequestTarget}
                        destinationRequestTarget={destinationRequestTarget}
                        formConfiguration={formConfiguration}
                        hasError={isInputError('destination')}
                        inputName={'destination'}
                        isInputDisabled={isInputDisabled(fields?.destination?.dependsOn)}
                        isOverlayVisible={isOverlayOpen && overlayType === 'destination'}
                        onInputClick={() => onInputClickHandler('destination', fields?.destination?.dependsOn)}
                        onOverlayClose={onOverlayClose}
                        onOverlayCtaClick={onDestinationOverlayCtaClick}
                    />
                )}
                {fields?.airports && (
                    <TravelFormAirportOverlayContainerDesktop
                        airports={travelForm?.filters.airports}
                        airportsRequestTarget={airportRequestTarget}
                        formConfiguration={formConfiguration}
                        hasError={isInputError('airports')}
                        inputName={'airports'}
                        isOverlayVisible={isOverlayOpen && overlayType === 'airports'}
                        onInputClick={() => onInputClickHandler('airports')}
                        onOverlayClose={onOverlayClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )}
                {fields?.date && (
                    <TravelFormDatePickerOverlayContainerDesktop
                        date={travelForm?.filters.date}
                        dateRequestTarget={dateRequestTarget}
                        formConfiguration={formConfiguration}
                        hasError={isInputError('date')}
                        inputName={'date'}
                        isInputDisabled={isInputDisabled(fields?.date?.dependsOn)}
                        isOverlayVisible={isOverlayOpen && overlayType === 'date'}
                        onInputClick={() => onInputClickHandler('date', fields?.date?.dependsOn)}
                        onOverlayClose={onOverlayClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )}
                {fields?.duration && (
                    <TravelFormDurationOverlayContainerDesktop
                        duration={travelForm?.filters.duration}
                        formConfiguration={formConfiguration}
                        hasError={isInputError('duration')}
                        inputName={'duration'}
                        isOverlayVisible={isOverlayOpen && overlayType === 'duration'}
                        onInputClick={() => onInputClickHandler('duration')}
                        onOverlayClose={onOverlayClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                    />
                )}
                {fields?.roomAllocation && (
                    <TravelFormRoomAllocationContainerDesktop
                        formConfiguration={formConfiguration}
                        hasError={isInputError('roomAllocation')}
                        inputName={'roomAllocation'}
                        isOverlayVisible={isOverlayOpen && overlayType === 'roomAllocation'}
                        onInputClick={() => onInputClickHandler('roomAllocation')}
                        onOverlayClose={onOverlayClose}
                        onOverlayCtaClick={onOverlayCtaClick}
                        roomAllocation={travelForm?.filters.roomAllocation}
                    />
                )}
                <Tooltip
                    content={
                        <div
                            className={'padding-10 font-size-12'}
                            // eslint-disable-next-line no-inline-styles/no-inline-styles
                            style={{ maxWidth: '137px' }}
                        >
                            {travelForm?.cta?.filterHintText}
                        </div>
                    }
                    tippyConfig={{
                        offset: [0, -5],
                        placement: 'right',
                        popperOptions: {
                            modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }],
                        },
                    }}
                    visible={
                        !isWidget && hasTravelFormChanged && !disableCtaTooltip && !!travelForm?.cta?.filterHintText
                    }
                >
                    <Button
                        fontSize={inputSize === 'large' ? 18 : 16}
                        fontWeight={'bold'}
                        height={inputSize === 'large' ? 60 : 50}
                        onClick={() => onSubmitHandler(formConfiguration.cta.dependsOn)}
                        qaId={'qa-travel-form-submit-button-desktop'}
                        style={{
                            minWidth: inputSize === 'large' ? 188 : 'unset',
                            width: inputSize === 'large' ? 188 : '100%',
                        }}
                        title={formConfiguration.cta.text}
                    />
                </Tooltip>
            </div>
        </div>
    );
}
