import type { ActiveDateSelectionType } from '@/core/features/date/date';
import type { DatePickerDateRange } from '@/core/features/date-picker/date-picker-types';

import React from 'react';

import { createDateRangeArray, getDestructuredDate, getFormatedDateRange } from '@/core/features/date/date';

type SelectedDateRange = {
    from: Date | number;
    to: Date | number;
};

export default function useDatePicker(
    selectedDate: SelectedDateRange | null,
    daysToNextBookableDate: number,
    maxPossibleDayRange: number,
    numOfMonthFromNow: number,
) {
    const [currentDateRange, setCurrentDateRange] = React.useState<DatePickerDateRange | undefined>(undefined);
    const [activeDateSelectionType, setActiveDateSelectionType] = React.useState<ActiveDateSelectionType>('start-date');
    const { date: todayDate, month: todayMonth, year: todayYear } = getDestructuredDate(new Date());

    const getLastNavigatableMonthInCalendar = () => {
        const lastDayOfMonth = new Date(todayYear, todayMonth + numOfMonthFromNow, 0);
        lastDayOfMonth.setDate(lastDayOfMonth.getDate());
        return lastDayOfMonth;
    };

    const getDisbaleDaysMatcher = () => {
        const firstPossiblePickDate = new Date(todayYear, todayMonth, todayDate + daysToNextBookableDate);

        if (!currentDateRange?.from) {
            return { before: firstPossiblePickDate };
        }

        if (currentDateRange.from && !currentDateRange?.to) {
            const { date, month, year } = getDestructuredDate(currentDateRange.from);
            return { after: new Date(year, month, date + maxPossibleDayRange - 1), before: firstPossiblePickDate };
        }

        return { before: firstPossiblePickDate };
    };

    const setInitialDateSelection = React.useCallback(() => {
        if (!selectedDate) {
            return;
        }
        setCurrentDateRange({
            from: new Date(selectedDate.from),
            to: new Date(selectedDate.to),
        });
    }, [selectedDate]);

    React.useEffect(() => {
        const formatedDateRange = getFormatedDateRange(currentDateRange);
        if (!currentDateRange || formatedDateRange.from === formatedDateRange.to) {
            setActiveDateSelectionType('start-date');
            return;
        }
        if (currentDateRange.from && !currentDateRange.to) {
            setActiveDateSelectionType('end-date');
            return;
        }
        setActiveDateSelectionType('start-date');
    }, [currentDateRange]);

    // since we load travelForm overlays before TravelFormDataV1 is fully loaded, we have to manage certain states manually
    React.useEffect(() => {
        if (!currentDateRange && selectedDate) {
            setInitialDateSelection();
        }
    }, [currentDateRange, selectedDate, setInitialDateSelection]);

    return {
        activeDateSelectionType,
        currentDateRange,
        dateRangeArray: createDateRangeArray(numOfMonthFromNow),
        disabledDaysMatcher: getDisbaleDaysMatcher(),
        lastNavigatableMonth: getLastNavigatableMonthInCalendar(),
        setActiveDateSelectionType,
        setCurrentDateRange,
        setInitialDateSelection,
    };
}
