import type {
    DatePickerDateRange,
    DatePickerDisabledDatesMatcher,
    DatePickerSelectRangeEventHandler,
} from '@/core/features/date-picker/date-picker-types';

import { DatePickerHorizontal } from '@/core/features';

type TravelFormDatePickerOverlayDesktopProps = {
    disabledDays: DatePickerDisabledDatesMatcher;
    fromDate: Date;
    onDateSelect: DatePickerSelectRangeEventHandler;
    selectedDate: DatePickerDateRange | undefined;
    toDate: Date;
};

export default function TravelFormDatePickerOverlayDesktop({
    disabledDays,
    fromDate,
    onDateSelect,
    selectedDate,
    toDate,
}: TravelFormDatePickerOverlayDesktopProps) {
    return (
        <div
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ minWidth: '686px' }}
        >
            <DatePickerHorizontal
                disabled={disabledDays}
                fromDate={fromDate}
                onDateSelect={onDateSelect}
                selectedDate={selectedDate}
                toDate={toDate}
            />
        </div>
    );
}
