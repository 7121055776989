import type {
    TravelFormAgeOptions,
    TravelFormRoomConfiguration,
    TravelFormRooms,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CounterWithLabel from '@/core/components/counter/counter-with-label';
import TravelFormAgeAllocationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/age-allocation/age-allocation-desktop/travel-form-room-age-allocation-desktop';
import {
    doChildrenWithoutAgeExist,
    getAdultsInRoom,
    getChildrenInRoom,
} from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';
import useTravelFormRoomAllocation from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/use-travel-form-room-allocation';

import styles from './travel-form-room-allocation-layer-desktop.module.scss';

type TravelFormRoomAllocationOverlayProps = {
    adultsConfig: TravelFormRoomConfiguration;
    ageOptions: TravelFormAgeOptions;
    childrenConfig: TravelFormRoomConfiguration;
    maxAdultsPerRoom: number;
    maxChildrenPerRoom: number;
    preSelectedRooms: TravelFormRooms;
    roomsConfig: TravelFormRoomConfiguration;
};

export default function TravelFormRoomAllocationLayerDesktop({
    adultsConfig,
    ageOptions,
    childrenConfig,
    maxAdultsPerRoom,
    maxChildrenPerRoom,
    preSelectedRooms,
    roomsConfig,
}: TravelFormRoomAllocationOverlayProps) {
    const { addAdult, addChild, addRoom, allocateChildAgeInRoom, allocation, removeAdult, removeChild, removeRoom } =
        useTravelFormRoomAllocation(preSelectedRooms, maxAdultsPerRoom, maxChildrenPerRoom);

    const handleDeleteOnClick = (event: React.MouseEvent, index: number) => {
        removeRoom(index);
    };

    return (
        <div className={styles.layerContent}>
            {allocation.rooms.map((room, index) => {
                const adults = getAdultsInRoom(room);
                const children = getChildrenInRoom(room);

                return (
                    <div
                        className={styles.roomConfig}
                        key={index}
                    >
                        <div className={'flex-space-between-horizontal'}>
                            <div className={styles.roomConfigHeaderTitle}>{`Zimmer ${index + 1}`}</div>
                            {index > 0 && (
                                <button
                                    className={styles.roomConfigHeaderRemoveButton}
                                    data-qa-id={'qa-travel-form-rooms-remove-desktop'}
                                    onClick={(event) => handleDeleteOnClick(event, index)}
                                >
                                    entfernen
                                </button>
                            )}
                        </div>
                        <CounterWithLabel
                            count={adults.length}
                            disableIncrease={adults.length === maxAdultsPerRoom}
                            isLabelError={
                                allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                                allocation.errors.minAdultsPerRoom.isError
                            }
                            isValueError={
                                allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                                allocation.errors.minAdultsPerRoom.isError
                            }
                            label={adultsConfig.label}
                            labelPosition={'space-between'}
                            max={maxAdultsPerRoom}
                            min={adultsConfig.min}
                            onDecrease={() => removeAdult(index)}
                            onIncrease={() => addAdult(index)}
                        />
                        <CounterWithLabel
                            count={children.length}
                            disableIncrease={children.length === maxChildrenPerRoom}
                            isLabelError={
                                allocation.errors.exceedsMaxChildrenPerRoom.isError ||
                                allocation.errors.exceedsMaxChildrenPerRoom.isError
                            }
                            isValueError={
                                allocation.errors.exceedsMaxChildrenPerRoom.isError ||
                                allocation.errors.exceedsMaxChildrenPerRoom.isError
                            }
                            label={childrenConfig.label}
                            labelPosition={'space-between'}
                            max={maxChildrenPerRoom}
                            min={childrenConfig.min}
                            onDecrease={() => removeChild(index)}
                            onIncrease={() => addChild(index)}
                        />
                        {children.length > 0 && (
                            <TravelFormAgeAllocationDesktop
                                ageOptions={ageOptions}
                                areAllChildrenAgesAllocated={!doChildrenWithoutAgeExist(children)}
                                handleOptionChange={(childIndex, value) =>
                                    allocateChildAgeInRoom(index, adults, children, childIndex, value)
                                }
                                listOfChildren={children}
                            />
                        )}
                    </div>
                );
            })}
            {allocation.roomsCount < roomsConfig.max && (
                <div className={styles.addRoomButtonContainer}>
                    <button
                        className={styles.addRoomButton}
                        data-qa-id={'qa-travel-form-room-add-desktop'}
                        onClick={() => addRoom('with-adult')}
                    >
                        + Zimmer hinzufügen
                    </button>
                </div>
            )}
        </div>
    );
}
