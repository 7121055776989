import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function ChevronLargeIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 18 65'}
            >
                <path
                    d={'M37.435 32.5 23.609 4.225 25.696 0l15.913 32.5L25.696 65l-2.087-4.225L37.435 32.5Z'}
                    transform={'translate(-23.609)'}
                    vectorEffect={'non-scaling-stroke'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}
