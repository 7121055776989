import type { OnClickEvent } from '@/core/types/react-overrides';
import type { TravelFormActivityResult } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import CheckBox from '@/core/components/check-box/check-box';
import { IconImage } from '@/core/features';
import { TravelFormActivityShimmerBoxDesktop } from '@/features/travel-form/travel-form-overlay/travel-form-shimmer-boxes-desktop';

import styles from './travel-form-activity-desktop.module.scss';

type TravelFormActivityDesktopProps = {
    activities: TravelFormActivityResult[];
    isLoading?: boolean;
    isMultiSelect: boolean;
    onActivityClick: (activityItem: TravelFormActivityResult) => void;
    selectedActivities: string[];
};

export default function TravelFormActivityDesktop({
    activities,
    isLoading,
    isMultiSelect,
    onActivityClick,
    selectedActivities,
}: TravelFormActivityDesktopProps) {
    if (isLoading) {
        return <TravelFormActivityShimmerBoxDesktop />;
    }

    const onClick = (event: OnClickEvent, activityItem: TravelFormActivityResult) => {
        event.stopPropagation();
        onActivityClick(activityItem);
    };
    return (
        <div className={styles.travelFormActivity}>
            {activities.map((activity) => (
                <div
                    className={styles.activityItem}
                    data-qa-id={'qa-travel-form-activity-item-desktop'}
                    key={activity.name}
                    onClick={(event) => onClick(event, activity)}
                >
                    <div className={'flex gap-20 align-center'}>
                        <IconImage
                            disablePreload={true}
                            height={25}
                            url={activity.iconUrl}
                            width={25}
                        />
                        <div>{activity.title}</div>
                    </div>
                    {isMultiSelect ? (
                        <CheckBox
                            isSelected={selectedActivities.includes(activity.name)}
                            onClick={(event) => onClick(event, activity)}
                        />
                    ) : (
                        <div className={`flex-center ${styles.activityItemCount}`}>{activity.count}</div>
                    )}
                </div>
            ))}
        </div>
    );
}
