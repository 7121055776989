import type { OnClickEvent } from '@/core/types/react-overrides';

import React from 'react';

import ChevronLargeIcon from '@/assets/icons/chevron-large';
import { WindowCloseIcon } from '@/assets/icons/window-close';
import { Button } from '@/core/features';
import useOutsideClick from '@/core/features/event-listener/use-outside-click';
import color from '@/core/features/styles/color';
import zIndex from '@/core/features/styles/z-index';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './travel-form-input-desktop.module.scss';

const bem = bemModule(styles);

type TravelFormInputDesktopProps = React.PropsWithChildren<{
    autoFocus?: boolean;
    ctaText?: string;
    errorLabel?: string;
    filterValue: null | string;
    hasError?: boolean;
    hideCta?: boolean;
    inputSize: 'large' | 'normal';
    isCtaDisabled?: boolean;
    isInputDisabled?: boolean;
    isLoading?: boolean;
    isOverlayVisible: boolean;
    label: string;
    maxHeight?: number;
    minWidth?: '100%' | number;
    onChange?: (value: string) => void;
    onClear?: () => void;
    onClick: (event: OnClickEvent) => void;
    onClose?: () => void;
    onCtaClick?: () => void;
    onOutsideClick?: () => void;
    placeholder: string;
    qaId?: string;
    type: 'cta' | 'dropdown' | 'input';
}>;

export default function TravelFormInputDesktop({
    autoFocus = false,
    children,
    ctaText,
    errorLabel,
    filterValue,
    hasError,
    hideCta,
    inputSize,
    isCtaDisabled,
    isInputDisabled = false,
    isLoading,
    isOverlayVisible,
    label,
    maxHeight = 414,
    minWidth = 414,
    onChange,
    onClear,
    onClick,
    onClose,
    onCtaClick,
    onOutsideClick,
    placeholder,
    qaId,
    type,
}: TravelFormInputDesktopProps) {
    const [inputValue, setInputValue] = React.useState<string>('');
    const inputWrapperRef = React.useRef<HTMLDivElement | null>(null);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const containerRef = useOutsideClick(() => {
        /* The onClose callback of the first parent component that uses this component is always used here first.
		To ensure that the onClose callback is only executed for the currently open overlay, this guard is added. */
        if (!isOverlayVisible) {
            return;
        }
        clearInput();
        onOutsideClick?.();
        onCtaClick?.();
        onClose?.();
    });

    const isClearable = inputRef.current?.value && inputValue !== '' && type === 'input' && !isInputDisabled;

    if (inputRef.current && type === 'input') {
        inputRef.current.disabled = isInputDisabled;
        if (!isInputDisabled || isOverlayVisible) {
            inputRef.current.focus();
        }
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        onChange?.(event.target.value);
    };

    const clearInput = () => {
        if (!inputRef?.current) {
            return;
        }
        inputRef.current.value = '';
        setInputValue('');
    };

    const handleOnClear = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        clearInput();
        onClear?.();
    };

    const handleOnClick = (event: OnClickEvent) => {
        event.stopPropagation();
        if (type === 'cta' || type === 'dropdown') {
            onClick(event);
            return;
        }
        if (!inputRef?.current) {
            return;
        }
        onClick(event);
    };

    const handleOnCtaClick = () => {
        clearInput();
        onCtaClick?.();
    };

    const inputOverlayMinWidth = (() => {
        if (type !== 'dropdown' || minWidth) {
            return minWidth === '100%' ? minWidth : `${minWidth}px`;
        }

        if (inputWrapperRef.current) {
            return `${inputWrapperRef.current.offsetWidth}px`;
        }

        return undefined;
    })();

    return (
        <div
            className={styles.travelFormInputOverlayDesktopWrapper}
            data-qa-id={qaId}
            /* Workaround for handling outside clicks, as every click on the overlay content triggers the closure of the overlay */
            onClick={(event) => event.stopPropagation()}
            ref={containerRef}
        >
            <div
                className={bem(styles.wrapper, { hasError, [inputSize]: true, isActive: isOverlayVisible })}
                ref={inputWrapperRef}
            >
                <div className={styles.label}>{hasError ? errorLabel : label}</div>
                <div className={'flex-space-between-horizontal'}>
                    <div className={styles.inputWrapper}>
                        <input
                            autoFocus={autoFocus}
                            className={`ellipsis ${bem(styles.input, {
                                [`${inputSize}${type === 'dropdown' ? '-dropdown' : ''}`]: true,
                                [`${inputSize}`]: true,
                                isActive: isOverlayVisible,
                            })}`}
                            disabled={type === 'cta' || type === 'dropdown' || isInputDisabled}
                            onChange={handleOnChange}
                            onClick={handleOnClick}
                            placeholder={placeholder}
                            ref={inputRef}
                            value={(isOverlayVisible ? inputValue : filterValue) ?? ''}
                        />
                        {(isInputDisabled || type === 'cta' || type === 'dropdown') && (
                            <div
                                className={styles.inputOverlay}
                                onClick={handleOnClick}
                            />
                        )}
                    </div>
                    {isClearable && (
                        <div
                            className={`flex-center ${styles.closeIcon}`}
                            onClick={handleOnClear}
                        >
                            <WindowCloseIcon
                                color={color('white')}
                                height={9}
                                width={9}
                            />
                        </div>
                    )}
                    {type === 'dropdown' && (
                        <ChevronLargeIcon
                            color={color('dustyGray')}
                            height={20}
                            onClick={handleOnClick}
                            rotate={isOverlayVisible ? -90 : 90}
                            width={20}
                        />
                    )}
                </div>
            </div>
            {isOverlayVisible && (
                <div
                    className={bem(styles.travelFormInputOverlayDesktop, { [inputSize]: true })}
                    style={{
                        minWidth: inputOverlayMinWidth,
                        zIndex: zIndex('travelFormInputOverlayDesktop'),
                    }}
                >
                    <div
                        className={styles.overlayContent}
                        style={{ maxHeight: `${maxHeight}px` }}
                    >
                        {children}
                        {!hideCta && ctaText && !isLoading && (
                            <div className={styles.submitButtonContainer}>
                                <Button
                                    disabled={isCtaDisabled}
                                    fontSize={18}
                                    noShadow={false}
                                    onClick={handleOnCtaClick}
                                    qaId={`qa-travel-form-input-submit-desktop-${isLoading ? 'loading' : 'loaded'}`}
                                    title={ctaText}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
