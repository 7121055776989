import type {
    TravelFormDataV1Attributes,
    TravelFormDurationSection,
    TravelFormDurationSectionKey,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

type TravelFormDurationState = {
    activeModule: keyof TravelFormDurationSection;
    activePresetChipText: null | string;
    counter: number;
    dayRange: {
        max: number;
        min: number;
    };
};

export default function useDurationOverlay(config: {
    duration?: TravelFormDataV1Attributes['filters']['duration'];
    mode?: 'auto-update' | 'none';
}) {
    const { duration, mode = 'none' } = config;

    const initialState = {
        activeModule: getActiveSection(duration?.section),
        activePresetChipText: duration?.section.preset.options.find((preset) => preset.isSelected)?.value ?? null,
        counter: duration?.section.counter.selectedDays ?? 1,
        dayRange: {
            max: duration?.section.range.to ?? 10,
            min: duration?.section.range.from ?? 1,
        },
    };

    const [durationState, setDurationState] = React.useState<TravelFormDurationState>(initialState);
    const { activeModule, activePresetChipText, counter, dayRange } = durationState;

    const onRangeChange = (range: { max: number; min: number }) => {
        if (!duration) {
            return;
        }

        setDurationState((prevState) => ({
            ...prevState,
            dayRange: range,
        }));
    };

    const setActiveModule = (activeModule: keyof TravelFormDurationSection) => {
        setDurationState((prevState) => ({
            ...prevState,
            activeModule,
        }));
    };

    const setCounter = (counter: number) => {
        setDurationState((prevState) => ({
            ...prevState,
            counter,
        }));
    };

    const setActivePresetChipText = (activePresetChipText: string) => {
        setDurationState((prevState) => ({
            ...prevState,
            activePresetChipText,
        }));
    };

    const resetDurationState = () => {
        setDurationState(initialState);
    };

    /**
     * This hook currently exists as a workaround to force a re-render if
     * needed when the duration filter data changes. It is currently only
     * used in the desktop component, where the render logic causes differences
     * between the container state and the overlaystate.
     * */
    React.useEffect(() => {
        if (mode === 'none') {
            return;
        }
        if (duration) {
            setDurationState((prevState) => ({
                ...prevState,
                activeModule: getActiveSection(duration.section),
                activePresetChipText:
                    duration.section.preset.options.find((preset) => preset.isSelected)?.value ?? null,
                counter: duration.section.counter.selectedDays ?? 1,
                dayRange: {
                    max: duration.section.range.to ?? 10,
                    min: duration.section.range.from ?? 1,
                },
            }));
        }
    }, [mode, duration]);

    const activeDurationPlaceholderText = React.useMemo(() => {
        switch (activeModule) {
            case 'preset':
                return (
                    duration?.section.preset.options.find((preset) => preset.value === activePresetChipText)?.text ?? ''
                );
            case 'counter':
                return `${counter.toString()} Tage`;
            case 'range':
                return `${dayRange.min}-${dayRange.max} Tage`;
            case 'exactDays':
                return duration?.section.exactDays?.value ?? 'exakt';
        }
    }, [
        activeModule,
        activePresetChipText,
        counter,
        dayRange.max,
        dayRange.min,
        duration?.section.exactDays?.value,
        duration?.section.preset.options,
    ]);

    return {
        activeDurationPlaceholderText,
        activeModule,
        activePresetChipText,
        counter,
        dayRange,
        onRangeChange,
        resetDurationState,
        setActiveModule,
        setActivePresetChipText,
        setCounter,
    };
}

const getActiveSection = (section: TravelFormDurationSection | undefined): TravelFormDurationSectionKey => {
    if (!section) {
        return 'preset';
    }

    const initialActiveModule = Object.entries(section).flatMap((item) =>
        item[1]?.isSelected ? (item[0] as TravelFormDurationSectionKey) : [],
    );

    if (!initialActiveModule[0]) {
        return 'preset';
    }

    return initialActiveModule[0];
};
