import type { LinkProps, LinkType } from '@/core/features/link/link';
import type { SafeOmit } from '@/core/types/safe-omit';

import React from 'react';

import { Link } from '@/core/features';

export type LinkWrapperProps = SafeOmit<LinkProps, 'href' | 'linkType' | 'onClick'> & {
    href: LinkProps['href'] | null | undefined;
    linkType?: LinkType | null | undefined;
    onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, Event>) => void;
    useWrapper?: boolean;
};

export default function ConditionalLinkWrapper(props: React.PropsWithChildren<LinkWrapperProps>) {
    const { href, linkType, onLinkClick, relType: _relType, ...restProps } = props;

    if (href && !!linkType && linkType !== 'scroll') {
        return (
            <Link
                {...restProps}
                href={href}
                linkType={linkType}
                onClick={onLinkClick}
            />
        );
    }
    if (props.useWrapper) {
        return (
            <div
                className={props.className}
                style={props.style}
            >
                {props.children}
            </div>
        );
    }
    return <>{props.children}</>;
}
