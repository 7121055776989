import type {
    TravelFormDestinationResult,
    TravelFormDestinationResultSetType,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CheckBox from '@/core/components/check-box/check-box';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './travel-form-destination-result-set-desktop.module.scss';

const bem = bemModule(styles);

type TravelFormDestinationResultListDesktopProps = {
    onResultClick: (
        event: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
        destination: TravelFormDestinationResult,
    ) => void;
    resultSet: TravelFormDestinationResultSetType;
    selectedDestinationsByName: string[];
};

export default function TravelFormDestinationResultSetDesktop({
    onResultClick,
    resultSet,
    selectedDestinationsByName,
}: TravelFormDestinationResultListDesktopProps) {
    const destinations = resultSet.results;

    return (
        <div className={styles.resultList}>
            {resultSet.title && <div className={styles.resultSetTitle}>{resultSet.title}</div>}
            <div>
                {destinations.map((destination, index) => {
                    const isItemSelected = selectedDestinationsByName.includes(destination.name);
                    return (
                        <div
                            className={bem(styles.resultItem, {
                                isDefault: destination.isDefault,
                                isItemSelected,
                            })}
                            data-qa-id={'qa-travel-form-result-item-desktop'}
                            key={`${destination.name}-${index}`}
                            onClick={(event) => onResultClick(event, destination)}
                        >
                            <div>
                                <div className={styles.resultItemTitle}>{destination.title}</div>
                                <div className={styles.resultItemSubtitle}>{destination.subTitle}</div>
                            </div>
                            {resultSet.type === 'multiple' && !destination.isDefault ? (
                                <CheckBox
                                    isSelected={isItemSelected}
                                    onClick={(event) => onResultClick(event, destination)}
                                />
                            ) : (
                                destination.isDefault && (
                                    <div
                                        className={styles.resultSelectLabel}
                                        onClick={(event) => onResultClick(event, destination)}
                                    >
                                        wählen
                                    </div>
                                )
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
