import type {
    TravelFormActivityResult,
    TravelFormDataV1Attributes,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormDesktopFormConfiguration } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormActivityDesktop from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity-overlay-desktop/travel-form-activity-desktop';
import useTravelFormActivityOverlay from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/use-travel-form-activity-overlay';

type TravelFormActivityOverlayContainerDesktopProps = {
    activity: TravelFormDataV1Attributes['filters']['activity'] | undefined;
    activityRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => void;
};

export default function TravelFormActivityOverlayContainerDesktop({
    activity,
    activityRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
}: TravelFormActivityOverlayContainerDesktopProps) {
    const { isLoading, onActivityClick, results, selectedActivities, selectedActivitiesByName } =
        useTravelFormActivityOverlay({
            activityRequestTarget,
            selectedItems: activity?.selectedItems,
            selectionType: activity?.selectionType,
        });

    const inputConfiguration = formConfiguration.fields?.activity;
    const isDataIncomplete = !activity || !activityRequestTarget;
    const inputLabel = activity?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = activity?.placeholderText ?? inputConfiguration?.placeholderText;

    const handleActivityClick = (activityItem: TravelFormActivityResult) => {
        if (!activity?.name) {
            return;
        }
        onActivityClick(activityItem, () => onOverlayCtaClick(activity.name, activityItem.name, activityRequestTarget));
        if (activity.selectionType === 'single') {
            onOverlayClose();
        }
    };

    const handleOnInputClick = () => {
        if (isOverlayVisible) {
            onOverlayClose();
            return;
        }
        onInputClick(inputName);
    };

    const onSubmit = () => {
        if (!activity?.name) {
            return;
        }
        if (selectedActivities.length === 0) {
            onOverlayCtaClick(activity.name, null, activityRequestTarget);
            return;
        }
        onOverlayCtaClick(activity.name, selectedActivitiesByName.join(','), activityRequestTarget);
    };

    return (
        <TravelFormInputWrapperDesktop
            ctaText={
                (selectedActivities.length > 1 ? activity?.ctaText.plural : activity?.ctaText.singular) ??
                'Aktivität übernehmen'
            }
            errorLabel={inputConfiguration?.error?.text}
            filterValue={placeholderText ?? 'Aktivität'}
            hasError={hasError}
            inputSize={formConfiguration.inputSize}
            isInputDisabled={!isOverlayVisible}
            isOverlayVisible={isOverlayVisible}
            label={inputLabel ?? 'Aktivität'}
            maxHeight={414}
            onClick={handleOnInputClick}
            onClose={onOverlayClose}
            onCtaClick={onSubmit}
            onOutsideClick={onSubmit}
            placeholder={placeholderText ?? 'Aktivität'}
            qaId={`qa-travel-form-input-activity-desktop${!isDataIncomplete ? '-loaded' : ''}`}
            type={'dropdown'}
        >
            <TravelFormActivityDesktop
                activities={results ?? []}
                isLoading={isDataIncomplete || isLoading}
                isMultiSelect={activity?.selectionType === 'multiple'}
                onActivityClick={handleActivityClick}
                selectedActivities={selectedActivitiesByName}
            />
        </TravelFormInputWrapperDesktop>
    );
}
