import type { DatePickerDateRange } from '@/core/features/date-picker/date-picker-types';

import { TIME_24H } from '@/core/utils/time';

export type DateFormatOptions = {
    day?: '2-digit' | 'numeric';
    month?: '2-digit' | 'long' | 'short';
    year?: '2-digit' | 'numeric' | null;
};
export type ActiveDateSelectionType = 'end-date' | 'start-date';
export const weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

/** This function creates an array of date entries from to iterate through and render the
 * same number of date pickers according to the length of the resulting array.
 *
 * for example: so ["2023-04-01", "2023-05-01"] would be used to render two date-picker components for april and may */
export const createDateRangeArray = (monthFromNow: number, startDate: Date = new Date()): Date[] => {
    return Array.from({ length: monthFromNow }, (_, index) => {
        return new Date(startDate.getUTCFullYear(), startDate.getUTCMonth() + index, 1);
    });
};

/**
 * returns an array of every past date of the month from the given start Date.
 */
export const getPastDaysInCurrentMonth = (startDate: Date = new Date()): Date[] => {
    return Array.from({ length: startDate.getDate() - 1 }).map((_, index) => {
        return new Date(startDate.getFullYear(), startDate.getMonth(), index + 1);
    });
};

export const getDestructuredDate = (date: Date) => {
    return { date: date.getDate(), day: date.getDay(), month: date.getMonth(), year: date.getFullYear() };
};

/**
 * Formats a given date into a localized string.
 * output date format: 1.Januar 2000
 */
export const getFormatedDate = (date: Date, options?: DateFormatOptions): string => {
    if (options?.year === null) {
        return date.toLocaleString('de-DE', {
            day: options?.day ?? 'numeric',
            month: options?.month ?? 'long',
        });
    }
    return date.toLocaleString('de-DE', {
        day: options?.day ?? 'numeric',
        month: options?.month ?? 'long',
        year: options?.year ?? 'numeric',
    });
};

export const getFormatedDateShort = (date: Date): string => {
    return date.toLocaleString('de-DE', {
        dateStyle: 'short',
    });
};

// output format: Mi. 9. Aug.
export const getFormatedDateAsWeekdayDayMonth = (date: Date) => {
    return date
        .toLocaleString('de-DE', {
            day: 'numeric',
            month: 'short',
            weekday: 'short',
        })
        .replace(',', '')
        .replace('Sept.', 'Sep.');
};

export const getFormatedDateRange = (dateRange: DatePickerDateRange | undefined) => {
    return {
        from: dateRange?.from ? getFormatedDate(dateRange?.from) : undefined,
        to: dateRange?.to ? getFormatedDate(dateRange?.to) : undefined,
    };
};

export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
    const days = getNumberOfDaysBetween(startDate, endDate);
    return Array.from({ length: days }, (_, index) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + index);
        return currentDate;
    });
};

const getNumberOfDaysBetween = (startDate: Date, endDate: Date): number => {
    return Math.ceil((endDate.getTime() - startDate.getTime()) / TIME_24H);
};

export const dateSelectHandler = (
    dateRange: DatePickerDateRange | undefined,
    activeDateSelectionType: ActiveDateSelectionType,
    previousDateRange: DatePickerDateRange | undefined,
) => {
    if (!dateRange) {
        return dateRange;
    }

    const { from, to } = dateRange;

    const formatedFrom = from ? getFormatedDate(from) : undefined;
    const formatedTo = to ? getFormatedDate(to) : undefined;

    if (!previousDateRange) {
        return dateRange;
    }

    const { from: prevFrom, to: prevTo } = previousDateRange;
    const formatedPrevFrom = prevFrom ? getFormatedDate(prevFrom) : undefined;
    const formatedPrevTo = prevTo ? getFormatedDate(prevTo) : undefined;

    /* Case: selectionType is "end-date" and there hasn't been a to date before */
    if (activeDateSelectionType !== 'start-date' && formatedTo && !formatedPrevTo) {
        /**
         * The internal dateRange handling of the datePicker causes that if the "to"
         * date is behind the "from" date, it becomes the future "from" date and
         * the "from" date becomes the "to" date. This way we can cancel
         * out this behavior
         */

        /* Case: from date can not be the same date as to date */
        if (formatedFrom === formatedTo) {
            return { from, to: undefined };
        }

        /* Case: if to date is behind prev from date -> from date is updated */
        if (formatedTo === formatedPrevFrom) {
            return { from, to: undefined };
        }

        /* Case: if previously there was no to date -> to date gets set */
        return { from, to };
    }

    if (activeDateSelectionType !== 'start-date') {
        return { from, to };
    }

    if (activeDateSelectionType === 'start-date' && formatedTo && formatedPrevFrom) {
        if (from && prevFrom && from < prevFrom) {
            return { from, to: prevTo };
        }

        /* Case: if there is a new from date ->  from date is updated  */
        if (formatedPrevFrom !== formatedFrom) {
            return { from, to: undefined };
        }

        /**  Case: default datePicker behaviour only replaces to date in this case, since
         * we wan't the new to date to become the new from date we have to handle this manually */
        if (formatedTo !== formatedFrom) {
            return { from: to, to: undefined };
        }

        /* Case: if prevFrom date and from date are the same -> reset date selection */
        if (formatedFrom === formatedPrevFrom) {
            return undefined;
        }
        /**
         * Again cancelling the default datePicker behaviour by not extending the
         * date range but setting the next "to" date as "from" date
         */
        return { from: to, to: undefined };
    }

    /**
     * If a valid date with from and to date exists in the date picker library,
     * a new click on the to date is interpreted as a new selection of the
     * from date. But we want to interpret this as a reset of the selection,
     * so we reset the whole selection here
     */
    if (activeDateSelectionType === 'start-date' && formatedPrevTo && !formatedTo) {
        return undefined;
    }

    /* Everything else is interpreted as a intial date selection */
    return { from, to: undefined };
};

// Format date from '30.04.2010' to '2010-04-30' (Y-m-d)
export const userFormInputDateToApiDate = (date: string): string => {
    const [day, month, year] = date.split('.');
    if (!day || !month || !year) {
        return '';
    }
    return dateToApiDate(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
};

// Format Date Object to '2010-04-30' (Y-m-d)
export const dateToApiDate = (date: Date) => {
    const year = date.getFullYear().toString();
    /* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart?retiredLocale=de */
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // example: 8.padStart(2, "0") -> '08'
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const isSameDate = (date1: Date, date2: Date) => {
    return dateToApiDate(date1) === dateToApiDate(date2);
};

export const getNumberOfMonthsBetween = (startDate: Date, endDate: Date) => {
    const endMonths = endDate.getMonth() + (endDate.getFullYear() - startDate.getFullYear()) * 12;
    return endMonths - startDate.getMonth();
};

export const dateToISODateWithTimezone = (date: Date) => {
    const timeZoneOffset = -date.getTimezoneOffset() / 60;
    const timeZoneOffsetHours = Math.floor(timeZoneOffset);
    const timeZoneOffsetMinutes = (timeZoneOffset - timeZoneOffsetHours) * 60;
    const timeZoneOffsetHoursFormatted = Math.abs(timeZoneOffsetHours).toString().padStart(2, '0');
    const timeZoneOffsetMinutesFormatted = timeZoneOffsetMinutes.toString().padStart(2, '0');
    const timeZoneOffsetFormatted = `${timeZoneOffset > 0 ? '+' : '-'}${timeZoneOffsetHoursFormatted}:${timeZoneOffsetMinutesFormatted}`;

    // 2024-08-10T00:00:00.123Z -> 2024-08-10T00:00:00+02:00
    return date.toISOString().replace(/\.\d+Z/, timeZoneOffsetFormatted);
};
