import type {
    TravelFormDataV1Attributes,
    TravelFormDurationPreset,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormDesktopFormConfiguration } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import { logger } from '@/core/features/logger/logger';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormDurationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/travel-form-duration-overlay-desktop/travel-form-duration-desktop';
import useDurationOverlay from '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/use-duration-overlay';
import { useIsTravelFormDataLoading } from '@/features/travel-form/travel-form-state';

type TravelFormDurationOverlayContainerDesktopProps = {
    duration: TravelFormDataV1Attributes['filters']['duration'] | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (filterName: TravelFormFilterName, filterValue: TravelFormFilterValue) => void;
};

export default function TravelFormDurationOverlayContainerDesktop({
    duration,
    formConfiguration,
    hasError,
    inputName,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
}: TravelFormDurationOverlayContainerDesktopProps) {
    const {
        activeDurationPlaceholderText,
        activeModule,
        activePresetChipText,
        counter,
        dayRange,
        onRangeChange,
        resetDurationState,
        setActiveModule,
        setActivePresetChipText,
        setCounter,
    } = useDurationOverlay({ duration, mode: 'auto-update' });

    const isTravelFormDataLoading = useIsTravelFormDataLoading();

    const inputConfiguration = formConfiguration.fields?.duration;
    const inputLabel = duration?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = duration?.placeholderText ?? inputConfiguration?.placeholderText;

    const [wasActivelyUsed, setWasActivelyUsed] = React.useState<boolean>(false);

    const handleOverlayCtaClick = () => {
        if (!duration?.name) {
            return;
        }
        setWasActivelyUsed(true);

        switch (activeModule) {
            case 'preset':
                if (!activePresetChipText) {
                    return;
                }
                onOverlayCtaClick(duration.name, activePresetChipText);
                break;
            case 'counter':
                onOverlayCtaClick(duration.name, counter.toString());
                break;
            case 'range':
                onOverlayCtaClick(duration.name, `${dayRange.min}-${dayRange.max}`);
                break;
            case 'exactDays':
                if (!duration.section.exactDays) {
                    return;
                }
                onOverlayCtaClick(duration.name, duration.section.exactDays.value);
                break;
            default:
                logger.error(`Could not apply filter because of unhandled duration activeModule: ${activeModule}.`);
                break;
        }
    };

    const handlePresetChipClick = (presetChip: TravelFormDurationPreset) => {
        if (!duration?.name) {
            return;
        }

        setWasActivelyUsed(true);
        setActivePresetChipText(presetChip.value);
        onOverlayCtaClick(duration?.name, presetChip.value);
    };

    const onCalendarPickClick = (exactDays: string) => {
        if (!duration?.name) {
            return;
        }

        setActivePresetChipText(exactDays);
        onOverlayCtaClick(duration?.name, exactDays);
    };

    const handleOverlayClose = () => {
        resetDurationState();
        onOverlayClose();
    };

    return (
        <TravelFormInputWrapperDesktop
            ctaText={duration?.ctaText ?? 'Daten übernehmen'}
            filterValue={
                wasActivelyUsed
                    ? isTravelFormDataLoading && activeDurationPlaceholderText.length > 0
                        ? activeDurationPlaceholderText
                        : placeholderText ?? null
                    : null
            }
            hasError={hasError}
            inputSize={formConfiguration.inputSize}
            isOverlayVisible={isOverlayVisible}
            label={inputLabel ?? 'Reisedauer'}
            onChange={() => {}}
            onClick={() => onInputClick(inputName)}
            onClose={handleOverlayClose}
            onCtaClick={handleOverlayCtaClick}
            placeholder={isOverlayVisible ? activeDurationPlaceholderText : placeholderText ?? 'Dauer der Reise'}
            qaId={'qa-travel-form-input-duration-desktop'}
            type={'cta'}
        >
            <TravelFormDurationDesktop
                activeModule={activeModule}
                activePresetChipText={activePresetChipText}
                currentRangeValue={dayRange}
                dayCounter={counter}
                dayRangeMax={duration?.section.range.maxDays ?? 56}
                dayRangeMin={duration?.section.range.minDays ?? 2}
                exactDays={duration?.section.exactDays ?? null}
                onActiveModuleChange={setActiveModule}
                onCalendarPickClick={(value) => onCalendarPickClick(value)}
                onDayCountDecrease={(nextCount) => setCounter(nextCount)}
                onDayCountIncrease={(nextCount) => setCounter(nextCount)}
                onPresetChipClick={(presetChip) => handlePresetChipClick(presetChip)}
                onRangeChange={onRangeChange}
                presetChips={duration?.section.preset.options ?? []}
                rangeStep={duration?.section.range.steps ?? 1}
            />
        </TravelFormInputWrapperDesktop>
    );
}
