import type {
    TravelFormRoom,
    TravelFormRooms,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import { logger } from '@/core/features/logger/logger';
import { TravelFormRoomElement } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-types';

export const isChildWithoutAge = (person: number | string) => {
    return person === TravelFormRoomElement.CHILD_WITHOUT_AGE;
};

export const isChild = (person: number | string): person is number => {
    return typeof person === 'number';
};

export const isAdult = (person: number | string): person is string => {
    return person === TravelFormRoomElement.ADULT;
};

export const doChildrenWithoutAgeExist = (listOfChildren: number[]) => {
    return listOfChildren.some((child) => isChildWithoutAge(child));
};

export const getAdultsInRoom = (room: TravelFormRoom) => {
    try {
        return room.filter(isAdult);
    } catch (error) {
        logger.error(`Received faulty room instance: ${room}`);
        return [];
    }
};

export const getChildrenInRoom = (room: TravelFormRoom) => {
    try {
        return room.filter(isChild);
    } catch (error) {
        logger.error(`Received faulty room instance: ${room}`);
        return [];
    }
};

export const getAdultsCount = (roomsState: TravelFormRooms) => {
    return roomsState.flat().reduce<number>((previousValue, currentValue) => {
        return currentValue === TravelFormRoomElement.ADULT && typeof previousValue === 'number'
            ? previousValue + 1
            : previousValue;
    }, 0);
};

export const getRoomAllocationAsString = (roomsState: TravelFormRooms | undefined): string => {
    return roomsState?.map((room) => room.join('-')).join(',') ?? '';
};
