import React from 'react';

import { ChevronIcon } from '@/assets/icons/chevron';
import useOutsideClick from '@/core/features/event-listener/use-outside-click';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './dropdown-select.module.scss';

const bem = bemModule(styles);

export type DropdownSelectProps = {
    classname?: string;
    maxHeightOpened?: number;
    onChange: (value: string) => void;
    options: { title: string }[];
    qaId?: string;
    qaOptionId?: string;
    selectedValue: string;
};

export default function DropdownSelect({
    classname,
    maxHeightOpened = 185,
    onChange,
    options,
    qaId,
    qaOptionId,
    selectedValue,
}: DropdownSelectProps) {
    const [isOpen, setOpen] = React.useState(false);

    const closeDropdown = () => {
        if (!isOpen) {
            return;
        }
        setOpen(() => false);
    };

    const outsideClickRef = useOutsideClick(() => {
        closeDropdown();
    });

    const handleItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, itemTitle: string) => {
        event.stopPropagation();
        onChange(itemTitle);
        setOpen(false);
    };

    return (
        <div
            className={styles.select}
            ref={outsideClickRef}
        >
            <div
                className={`flex-space-between-horizontal ${bem(`${styles.selectedValue}`, { isOpen })} ${
                    classname ?? ''
                }`}
                data-qa-id={'qa-dropdown-select'}
                onClick={() => setOpen(!isOpen)}
            >
                {selectedValue}
                <span className={styles.iconWrapper}>
                    <ChevronIcon
                        color={color('dustyGray')}
                        height={6}
                        rotate={isOpen ? 0 : 180}
                        width={14}
                    />
                </span>
            </div>
            {isOpen && (
                <div
                    className={styles.dropdownWrapperDropdownSelect}
                    data-qa-id={qaId}
                    style={{ maxHeight: maxHeightOpened }}
                >
                    {options.map((item, index) => (
                        <div
                            className={styles.item}
                            data-qa-id={qaOptionId}
                            key={index}
                            onClick={(event) => handleItemClick(event, item.title)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
