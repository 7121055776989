import type { TravelFormDataV1_jsonld_page_read } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';
import useDynamicPageComponentByType from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-by-type';
import { useClientUrl } from '@/core/features/app/app-atoms';
import useAppEvents from '@/core/features/app/use-app-events';
import { useScrollToTarget } from '@/core/features/link/use-scroll-to-target';
import { useRouterLink } from '@/core/features/router/router-link';
import { getSearchParameterByName, hasSearchParamChanged } from '@/core/utils/url';
import { getRoomAllocationAsString } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';
import { useSetHasTravelFormChanged, useSetIsTravelFormLoading } from '@/features/travel-form/travel-form-state';

export type TravelFormFilterValue = { end: string; start: string } | null | string;

export default function useTravelFormDataV1(queryConfig?: { enabled?: boolean }) {
    const setHasTravelFormChanged = useSetHasTravelFormChanged();
    const component = useDynamicPageComponentByType<TravelFormDataV1_jsonld_page_read>('TravelFormDataV1');
    const initialTargetComponentIri = component?.['@id'] ?? null;

    const [targetComponentIri, setTargetComponentIri] = React.useState<null | string>(initialTargetComponentIri);
    const { navigate } = useRouterLink();
    const setIsTravelFormLoading = useSetIsTravelFormLoading();

    const appEvents = useAppEvents();
    const [clientUrl] = useClientUrl();

    const { data, isLoading } = useComponentDataByIri<TravelFormDataV1_jsonld_page_read>(targetComponentIri, {
        config: {
            staleTime: Infinity,
            ...queryConfig,
        },
        queryName: 'travelFormDataV1',
    });

    React.useEffect(() => {
        if (queryConfig?.enabled) {
            setTargetComponentIri(component?.['@id'] ?? null);
        }
    }, [component, queryConfig?.enabled]);

    React.useEffect(() => {
        setIsTravelFormLoading(isLoading);
    }, [isLoading, setIsTravelFormLoading]);

    const filters = data?.attributes?.filters;
    const destinationFilterName = filters?.destination?.name;
    const roomAllocationFilterName = filters?.roomAllocation?.name;

    const { scrollTo: scrollToTargetComponent } = useScrollToTarget(
        component?.attributes?.cta.scrollTo?.scrollToTarget ?? null,
    );

    const applyFilter = (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => {
        const targetIri = requestTarget ?? data?.['@id'];

        if (!targetIri) {
            return;
        }

        if (typeof filterName === 'string' && (typeof filterValue === 'string' || filterValue === null)) {
            const currentFilterValues =
                getSearchParameterByName(clientUrl, filterName)?.split(',').sort().join(',') ?? '';
            const initialSelectedRoomsAsString = getRoomAllocationAsString(
                component?.attributes?.filters?.roomAllocation?.configuration.preSelectedRooms,
            );

            // Filter changed
            if (
                currentFilterValues !== (filterValue ?? '').split(',').sort().join(',') &&
                filterName !== destinationFilterName && // Ignore Destination Filter changes because they get directly redirected
                !(filterName === roomAllocationFilterName && filterValue === initialSelectedRoomsAsString) // Change to Initial Room Allocation should not suggest a filter change
            ) {
                setHasTravelFormChanged(true);
            }

            setFilter(targetIri, { [filterName]: filterValue });

            if (destinationFilterName === filterName) {
                appEvents.emit('destination_change');
            }

            return;
        }

        if (typeof filterName === 'object' && (typeof filterValue === 'object' || filterValue === null)) {
            if (!filterValue) {
                return;
            }

            const currentDateFilterValueStart = getSearchParameterByName(clientUrl, filterName.start);
            const currentDateFilterValueEnd = getSearchParameterByName(clientUrl, filterName.end);

            // Date Filter changed
            if (currentDateFilterValueEnd !== filterValue.end || currentDateFilterValueStart !== filterValue.start) {
                setHasTravelFormChanged(true);
            }

            setFilter(targetIri, { end: filterValue.end, start: filterValue.start });
            return;
        }
    };

    const setFilter = (targetIri: string, userQuery: Record<string, TravelFormFilterValue>) => {
        const updatedUrl = updateComponentIriParam('userQuery', targetIri, userQuery);
        setTargetComponentIri(updatedUrl.pathname + updatedUrl.search);
    };

    const submitForm = (linkUrl: string) => {
        setHasTravelFormChanged(false);
        if (linkUrl !== clientUrl) {
            if (
                typeof destinationFilterName === 'string' &&
                hasSearchParamChanged(clientUrl, linkUrl, destinationFilterName)
            ) {
                appEvents.emit('destination_change');
            }

            navigate(linkUrl);
            return;
        }
        scrollToTargetComponent();
    };

    return {
        activeFilters: data?.attributes?.activeFilters ?? component?.attributes?.activeFilters ?? [],
        applyFilter,
        initialCtaLinkUrl: component?.attributes?.cta.linkUrl,
        isLoading,
        requestTargets: {
            activityRequestTarget: data?.meta.activityRequestTarget,
            airportsRequestTarget: data?.meta.airportsRequestTarget,
            dateRequestTarget: data?.meta.dateRequestTarget,
            destinationChangeRequestTarget: data?.meta.destinationChangeRequestTarget,
            destinationRequestTarget: data?.meta.destinationRequestTarget,
        },
        scrollTo: component?.attributes?.cta.scrollTo,
        setTargetComponentIri,
        submitForm,
        toggleVertical: setTargetComponentIri,
        travelForm: data?.attributes ?? null,
    };
}
