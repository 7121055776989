import React from 'react';

import { IconImage } from '@/core/features';
import useWindowResize from '@/core/features/event-listener/use-window-resize';
import ConditionalLinkWrapper from '@/core/features/link/conditional-link-wrapper/conditional-link-wrapper';
import { removePageScrollPosition } from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';
import { useSetAtom } from '@/core/features/store/atom-store';
import { shouldInitMapBoundariesForComponentsAtom } from '@/features/map/map-data-v5/use-map-data-v5';
import { showMainContentAtom } from '@/features/page-layouts/desktop/desktop-map-search-layout';

export default function HeaderMapButtonDesktop({
    iconUrl,
    linkUrl,
    title,
    width,
}: {
    iconUrl: string;
    linkUrl: null | string;
    title: string;
    width: '120px' | '160px';
}) {
    const { windowSize } = useWindowResize();
    const isSmallViewPort = windowSize.innerWidth < 1364;

    const setInitMapBoundariesForComponents = useSetAtom(shouldInitMapBoundariesForComponentsAtom);

    const setShowMainContent = useSetAtom(showMainContentAtom);

    const onClick = () => {
        if (linkUrl) {
            setInitMapBoundariesForComponents(true);
            removePageScrollPosition(linkUrl);
        }
        setShowMainContent(!isSmallViewPort);
    };

    return (
        <ConditionalLinkWrapper
            href={linkUrl}
            linkType={'internal'}
        >
            <div
                className={
                    'flex-center width-100 border-radius-30 pointer text-catalinaBlue bg-white border border-catalinaBlue bold'
                }
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                style={{ height: '50px', width: width }}
            >
                <div
                    className={
                        'flex-center gap-10 padding-10 font-size-16 padding-left-15 padding-right-15 text-catalinaBlue border-radius-20'
                    }
                    onClick={onClick}
                >
                    <IconImage
                        height={16}
                        url={iconUrl}
                        width={20}
                    />
                    <div>{title}</div>
                </div>
            </div>
        </ConditionalLinkWrapper>
    );
}
