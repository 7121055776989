import React from 'react';

import { getLastElement } from '@/core/utils/array';

/** Is used as default zIndex for the first element to avoid overlapping. This of course implies that we assume the maximum number of stacked sticky elements cannot be more than 10 */
const MAX_STICKY_ELEMENTS = 10;
const STICKY_ATTRIBUTE = 'data-sticky';
const SCROLL_PADDING_ATTRIBUTE = 'data-scroll-padding';

type StickyElement = {
    considerForGlobalScrollPadding: boolean;
    element: HTMLElement;
    height: number;
    id: string;
    zIndex?: string;
};

/** * Custom hook for stacking sticky elements on the page. */
export default function useSticky(namespace: null | string, { skipZIndex = false }: { skipZIndex?: boolean } = {}) {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const [positionStyle, setPositionStyle] = React.useState<React.CSSProperties | null>(null);
    const [zIndex, setZIndex] = React.useState<string | undefined>();

    React.useEffect(() => {
        // delay to allow time for the browser to load/render all sticky elements. should not be noticeable to the user, as long as they don't scroll really fast immediately after the page is loaded.
        setTimeout(() => {
            if (!namespace || !ref.current) {
                return;
            }

            ref.current.setAttribute(STICKY_ATTRIBUTE, namespace);

            const allStickyElementsInDOM: StickyElement[] = [];
            document.querySelectorAll(`[${STICKY_ATTRIBUTE}]`).forEach((element, index) => {
                const style = window.getComputedStyle(element);
                const considerForGlobalScrollPadding = element.getAttribute(SCROLL_PADDING_ATTRIBUTE);

                if (element instanceof HTMLElement && !!namespace) {
                    const id = element.getAttribute(STICKY_ATTRIBUTE);
                    if (!id) {
                        return;
                    }
                    allStickyElementsInDOM.push({
                        considerForGlobalScrollPadding: !!considerForGlobalScrollPadding,
                        element: element,
                        height: element.offsetHeight,
                        id,
                        zIndex: skipZIndex ? '' : (parseInt(style.zIndex) + index).toString(),
                    });
                }
            });

            const currentIndex = allStickyElementsInDOM.findIndex(
                (element) => element.id === ref.current?.getAttribute(STICKY_ATTRIBUTE),
            );

            const stickyElementsBeforeCurrent = allStickyElementsInDOM.splice(0, currentIndex);

            const totalOffset = stickyElementsBeforeCurrent.reduce((acc, { element }) => {
                const marginBottom = element ? window.getComputedStyle(element).marginBottom : 0;
                const marginBottomValue = marginBottom ? Number(marginBottom.replace('px', '')) : 0;
                return acc + (element.offsetHeight ?? 0 + Math.abs(marginBottomValue));
            }, 0);

            setPositionStyle({
                position: 'sticky',
                top: `${totalOffset === 0 ? totalOffset : totalOffset - 1}px`,
            });
            // To ensure that sticky elements do not overlap with other elements when the page is scrolled programmatically,
            // we adjust the scroll-padding-top property. This padding is calculated based on the combined height of all relevant sticky elements.
            const globalScrollPaddingTop = allStickyElementsInDOM.reduce((acc, element) => {
                return element.considerForGlobalScrollPadding ? acc + element.height : acc;
            }, 0);
            document.querySelector('html')?.style.setProperty('scroll-padding-top', `${globalScrollPaddingTop}px`);

            if (skipZIndex) {
                return;
            }
            const elementAbove = getLastElement(stickyElementsBeforeCurrent)?.element;
            const zIndex = window.getComputedStyle(elementAbove ?? ref.current).zIndex;
            const isZIndexNaN = isNaN(Number(zIndex));
            // remove one from zIndex to avoid overlapping with the element above. e.g. when page is scrolled to bottom
            const computedZIndex = (() => {
                if (!elementAbove) {
                    return zIndex;
                } else if (isZIndexNaN) {
                    return zIndex;
                } else {
                    return `${Number(zIndex) - 1}`;
                }
            })();
            // isZIndexNaN = auto, inherit, initial, revert, revert-layer or unset
            setZIndex(isZIndexNaN ? `${MAX_STICKY_ELEMENTS}` : computedZIndex);
        }, 200);
    }, [skipZIndex, namespace]);

    return {
        ref,
        stickyStyle: {
            ...positionStyle,
            zIndex,
        },
    };
}
