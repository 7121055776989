import React from 'react';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import useDynamicPageComponentByIri from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-by-iri';
import {
    useDynamicPageComponentIndex,
    useForceRenderPageComponentIndex,
} from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-index';
import logger from '@/core/features/logger/logger';

export function useScrollToTarget(componentIri: null | string) {
    const [isForceRendering, setIsForceRendering] = React.useState(false);
    const scrollToComponent = useDynamicPageComponentByIri(componentIri, { matcher: (iri) => getComponentId(iri) });

    // If the component is a GroupV1, we want to render till the first component in the group
    const renderComponentIri =
        scrollToComponent && scrollToComponent['@type'] === 'GroupV1' && scrollToComponent.attributes.components[0]
            ? scrollToComponent.attributes.components[0]['@id']
            : componentIri;

    const renderPageComponentIndex = useDynamicPageComponentIndex(renderComponentIri);
    const [_, setForceRenderedPageComponentIndex] = useForceRenderPageComponentIndex();
    const scrollTo = React.useCallback(() => {
        if (!componentIri || !renderComponentIri) {
            return;
        }

        const scrollToComponentElement = document.querySelector(`#c_${getComponentId(componentIri)}`);
        const renderComponentElement = document.querySelector(`#c_${getComponentId(renderComponentIri)}`);

        if (!scrollToComponentElement || !renderComponentElement) {
            logger.error(
                `Scroll Component into View: Could not find component with id: ${getComponentId(
                    componentIri,
                )} or ${getComponentId(renderComponentIri)}`,
            );
            return;
        }

        setForceRenderedPageComponentIndex(renderPageComponentIndex);

        const interval = setInterval(() => {
            if (renderComponentElement.children.length === 0) {
                setIsForceRendering(true);
                return;
            }

            setIsForceRendering(false);
            clearInterval(interval);
            scrollToComponentElement.scrollIntoView({ behavior: 'smooth' });
        }, 16);

        return;
    }, [componentIri, renderComponentIri, renderPageComponentIndex, setForceRenderedPageComponentIndex]);

    return { isForceRendering, scrollTo };
}
