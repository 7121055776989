import { dataLayerPush } from '@/core/features/tracking/google-data-layer';

export default function useTravelFormTracking() {
    return { trackTravelFormCTAClick };
}

const trackTravelFormCTAClick = (activity: string) => {
    dataLayerPush({
        activity,
        element: 'Reiseformular_CTA',
        event: 'click',
    });
};
