/* https://benhoneywill.com/building-a-range-slider-component-in-react/ */
import React from 'react';

import styles from './range-slider.module.scss';

export type RangeSliderProps = {
    max: number;
    min: number;
    onChange: (values: { max: number; min: number }) => void;
    step?: number;
    value: { max: number; min: number };
};

/** In short: we have two input controls that determine the min and max values.
 * These two inputs are made invisible and replaced by the controlWrapper
 * since we only need them to determine the two min & max values.
 * We then take the  min & max values of the inputs to set
 * the position of the nodes in css of the controlWrapper which replaces the two inputs.  */
export default function RangeSlider({ max, min, onChange, step = 1, value }: RangeSliderProps) {
    const railRef = React.useRef<HTMLDivElement | null>(null);
    const rangeMiddle = max - min;
    const [minValue, setMinValue] = React.useState(value ? value.min : min);
    const [maxValue, setMaxValue] = React.useState(value ? value.max : max);

    const minControlNodePosition = ((minValue - min) / rangeMiddle) * 100;
    const maxControlNodePosition = ((maxValue - min) / rangeMiddle) * 100;

    React.useEffect(() => {
        if (value) {
            setMinValue(value.min);
            setMaxValue(value.max);
        }
    }, [value]);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const newMinVal = Math.min(parseInt(event.target.value), maxValue - step);
        if (!value) {
            setMinValue(newMinVal);
        }
        onChange({ max: maxValue, min: newMinVal });
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const newMaxVal = Math.max(parseInt(event.target.value), minValue + step);
        if (!value) {
            setMaxValue(newMaxVal);
        }
        onChange({ max: newMaxVal, min: minValue });
    };

    return (
        <div className={styles.rangeSlider}>
            <div className={styles.inputWrapper}>
                <input
                    className={styles.input}
                    max={max}
                    min={min}
                    onChange={handleMinChange}
                    step={step}
                    type={'range'}
                    value={minValue}
                />
                <input
                    className={styles.input}
                    max={max}
                    min={min}
                    onChange={handleMaxChange}
                    step={step}
                    type={'range'}
                    value={maxValue}
                />
            </div>
            <div className={styles.controlWrapper}>
                <div
                    className={styles.control}
                    style={{ left: `${minControlNodePosition}%` }}
                />

                <div
                    className={styles.rail}
                    ref={railRef}
                >
                    <div
                        className={styles.innerRail}
                        style={{
                            left: `${minControlNodePosition}%`,
                            right: `100 - ${maxControlNodePosition}%`,
                            width: `${maxControlNodePosition - minControlNodePosition}%`,
                        }}
                    />
                </div>
                <div
                    className={styles.control}
                    style={{ left: `${maxControlNodePosition}%` }}
                />
            </div>
        </div>
    );
}
