import type { DatePickerDateRange } from '@/core/features/date-picker/date-picker-types';
import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormDesktopFormConfiguration } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import {
    dateSelectHandler,
    dateToApiDate,
    getDatesBetween,
    getFormatedDateAsWeekdayDayMonth,
} from '@/core/features/date/date';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormDatePickerOverlayDesktop from '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/travel-form-date-picker-overlay-desktop/travel-form-date-picker-overlay-desktop';
import useDatePicker from '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/use-date-picker';
import { useIsTravelFormDataLoading } from '@/features/travel-form/travel-form-state';

type TravelFormDestinationOverlayContainerProps = {
    date: TravelFormDataV1Attributes['filters']['date'] | undefined;
    dateRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isInputDisabled: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => void;
};

export default function TravelFormDatePickerOverlayContainerDesktop({
    date,
    dateRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isInputDisabled,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
}: TravelFormDestinationOverlayContainerProps) {
    const {
        activeDateSelectionType,
        currentDateRange,
        disabledDaysMatcher,
        lastNavigatableMonth,
        setCurrentDateRange,
        setInitialDateSelection,
    } = useDatePicker(
        /** Setting 0 / null by default to satisfy typescript, since
         * we can't call useDatePicker hook conditionally. */
        date?.selectedDate ?? null,
        date?.daysToNextBookableDate ?? 0,
        date?.maxPossibleDayRange ?? 0,
        date?.numOfMonthFromNow ?? 0,
    );

    const inputConfiguration = formConfiguration.fields?.date;
    const inputLabel = date?.inputLabel ?? inputConfiguration?.inputLabel;
    const [wasActivelyUsed, setWasActivelyUsed] = React.useState<boolean>(false);
    const isTravelFormDataLoading = useIsTravelFormDataLoading();

    const onDateSelect = (dateRage: DatePickerDateRange | undefined) => {
        if (!date) {
            return;
        }
        setCurrentDateRange((previousDateRange) => {
            const nextDateRange = dateSelectHandler(dateRage, activeDateSelectionType, previousDateRange);

            if (!nextDateRange?.from || !nextDateRange?.to) {
                return nextDateRange;
            }

            setWasActivelyUsed(true);

            const isLessThenPrevDate =
                previousDateRange?.from && new Date(nextDateRange.from) < new Date(previousDateRange.from);

            const isMaxDayRangeExceeded =
                getDatesBetween(nextDateRange.from, nextDateRange.to).length > date.maxPossibleDayRange;

            if (isLessThenPrevDate || isMaxDayRangeExceeded) {
                return { from: nextDateRange.from, to: undefined };
            }

            onOverlayCtaClick(
                date.name,
                {
                    end: dateToApiDate(nextDateRange.to),
                    start: dateToApiDate(nextDateRange.from),
                },
                dateRequestTarget,
            );

            return nextDateRange;
        });
    };

    const handleOverlayClose = () => {
        if (!currentDateRange?.to) {
            setInitialDateSelection();
        }
        onOverlayClose();
    };

    const placeholderText = React.useMemo(() => {
        const placeholderText = date?.placeholderText ?? inputConfiguration?.placeholderText ?? 'beliebiger Zeitraum';

        const selectedPlaceholderTextFrom = currentDateRange?.from
            ? getFormatedDateAsWeekdayDayMonth(currentDateRange.from)
            : '';
        const selectedPlaceholderTextTo = currentDateRange?.to
            ? ` - ${getFormatedDateAsWeekdayDayMonth(currentDateRange?.to)}`
            : '';
        const selectedPlaceholderText = `${selectedPlaceholderTextFrom}${selectedPlaceholderTextTo}`;

        if ((isOverlayVisible || isTravelFormDataLoading) && selectedPlaceholderText.length) {
            return selectedPlaceholderText;
        }

        return placeholderText;
    }, [
        currentDateRange?.from,
        currentDateRange?.to,
        date?.placeholderText,
        inputConfiguration?.placeholderText,
        isOverlayVisible,
        isTravelFormDataLoading,
    ]);

    return (
        <TravelFormInputWrapperDesktop
            ctaText={date?.ctaText ?? 'Reisezeitraum auswählen'}
            errorLabel={date?.error?.text}
            filterValue={
                isTravelFormDataLoading ? placeholderText : wasActivelyUsed ? date?.placeholderText ?? null : null
            }
            hasError={hasError}
            hideCta
            inputSize={formConfiguration.inputSize}
            isInputDisabled={!isOverlayVisible || isInputDisabled}
            isOverlayVisible={isOverlayVisible}
            label={inputLabel ?? 'Reisezeitraum'}
            onClick={() => onInputClick(inputName)}
            onClose={handleOverlayClose}
            placeholder={placeholderText}
            qaId={'qa-travel-form-input-date-desktop'}
            type={'cta'}
        >
            <TravelFormDatePickerOverlayDesktop
                disabledDays={disabledDaysMatcher}
                fromDate={new Date()}
                onDateSelect={onDateSelect}
                selectedDate={currentDateRange}
                toDate={lastNavigatableMonth}
            />
        </TravelFormInputWrapperDesktop>
    );
}
