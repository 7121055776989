import { ShimmerBox } from '@/core/features';

export const TravelFormSearchShimmerBoxDesktop = () => {
    const shimmerBoxes = [
        { subtitle: '290px', title: '240px' },
        { subtitle: '280px', title: '230px' },
        { subtitle: '210px', title: '190px' },
        { subtitle: '60px', title: '200px' },
    ];
    return (
        <div className={'padding-20 padding-left-10'}>
            {shimmerBoxes.map((result, index) => {
                return (
                    <div
                        className={'flex-start-vertical padding-left-20 gap-5 wrap'}
                        key={index}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ height: 75 }}
                    >
                        <ShimmerBox
                            height={'18px'}
                            width={result.title}
                        />
                        <ShimmerBox
                            height={'16px'}
                            width={result.subtitle}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export const TravelFormActivityShimmerBoxDesktop = () => {
    const shimmerBoxItems = [
        { count: '30px', icon: '25px', title: '100px' },
        { count: '30px', icon: '25px', title: '100px' },
        { count: '30px', icon: '25px', title: '100px' },
        { count: '30px', icon: '25px', title: '100px' },
        { count: '30px', icon: '25px', title: '100px' },
    ];
    return (
        <div className={'padding-left-20 padding-right-20'}>
            {shimmerBoxItems.map((result, index) => {
                return (
                    <div
                        className={'flex-space-between-horizontal align-center'}
                        key={index}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ height: 60 }}
                    >
                        <div className={'gap-horizontal gap-20 align-center'}>
                            <ShimmerBox
                                height={'25px'}
                                width={result.icon}
                            />
                            <ShimmerBox
                                height={'16px'}
                                width={result.title}
                            />
                        </div>
                        <ShimmerBox
                            height={'16px'}
                            width={result.count}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export const TravelFormRoomAllocationShimmerBoxDesktop = () => {
    const shimmerBoxItems = [
        { button: '38px', label: '120px' },
        { button: '38px', label: '120px' },
        { button: '38px', label: '120px' },
    ];
    return (
        <div className={'padding-left-20 padding-right-20'}>
            {shimmerBoxItems.map((result, index) => {
                return (
                    <div
                        className={'flex-space-between-horizontal align-center'}
                        key={index}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ height: 60 }}
                    >
                        <ShimmerBox
                            height={'16px'}
                            width={result.label}
                        />
                        <div className={'flex align-center'}>
                            <ShimmerBox
                                height={result.button}
                                width={result.button}
                            />
                            <div
                                style={{
                                    height: result.button,
                                    width: result.button,
                                }}
                            />
                            <ShimmerBox
                                height={result.button}
                                width={result.button}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
